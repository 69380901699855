import React, { useEffect, useMemo } from "react";

import { navigate } from "gatsby";
import { useWatch } from "react-hook-form";

import styled from "@emotion/styled";

import useAddressApi from "../../../../../hooks/useAddressApi";
import Headline from "../../../../base/atoms/Headline";
import ReactHookInput from "../../../../shared/forms/ReactHookInput";
import ReactHookSelect, {
    SelectOptions,
} from "../../../../shared/forms/ReactHookSelect";
import LinkComponent from "../../../../shared/LinkComponent";
import Button from "../../../../shared/NewForms/Button";
import useSnackbar from "../../../../shared/Snackbar/store";
import useLiquidGasInquiryStore, {
    ContactForm,
    FormOfAddressEnum,
    useLiquidGasContactForm,
} from "./store";

const StreetRow = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    ${({ theme }) => theme.breakpoints.from.small.css`
       grid-template-columns: 4fr 1fr;
       gap: 10px;
    `}
`;

const CityRow = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    ${({ theme }) => theme.breakpoints.from.small.css`
       grid-template-columns: 1fr 2fr;
       gap: 10px;
    `}
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;

    & > button {
        max-width: 400px;
    }
`;

const LiquidGasContactForm = () => {
    const { notify } = useSnackbar();

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useLiquidGasContactForm();
    const { setCurrentQuestionnaireStep } = useLiquidGasInquiryStore(
        ({ setCurrentQuestionnaireStep }) => ({
            setCurrentQuestionnaireStep,
        })
    );
    const getAvailableFormsOfAddresses = (): SelectOptions<
        ContactForm,
        "formOfAddress"
    > => {
        return [
            { value: FormOfAddressEnum.MALE, text: "Herr" },
            { value: FormOfAddressEnum.FEMALE, text: "Frau" },
            { value: FormOfAddressEnum.DIVERSE, text: "Divers" },
        ];
    };

    const city = useWatch({
        control,
        name: "city",
    });

    const street = useWatch({
        control,
        name: "street",
    });

    const zipcode = useWatch({
        control,
        name: "zipcode",
    });

    const availableFormsOfAddresses = useMemo(getAvailableFormsOfAddresses, []);

    const { streets, cities, addressApiError } = useAddressApi({
        zipcode,
        city,
    });

    const goToQuestionnaire = () => {
        setCurrentQuestionnaireStep(0);
        void navigate("/fluessiggas/anfrage/fragebogen");
    };

    useEffect(() => {
        if (addressApiError) {
            const msg =
                addressApiError.message ??
                "Address-Suche fehlgeschlagen. Bitte versuche es erneut.";
            setValue("city", "");
            setValue("street", "");
            void notify("Fehler", msg);
        }
    }, [addressApiError]);

    return (
        <>
            <form onSubmit={handleSubmit(goToQuestionnaire)}>
                <Headline level={2}>Kontaktdaten</Headline>

                <ReactHookSelect<ContactForm, "formOfAddress">
                    errors={errors.formOfAddress}
                    name="formOfAddress"
                    placeholder="Anrede"
                    rules={{ required: true }}
                    control={control}
                    options={availableFormsOfAddresses}
                />

                <ReactHookInput<ContactForm, "firstname">
                    name="firstname"
                    placeholder="Vorname"
                    rules={{ required: true }}
                    control={control}
                    errors={errors?.firstname}
                />

                <ReactHookInput<ContactForm, "lastname">
                    name="lastname"
                    placeholder="Nachname"
                    rules={{ required: true }}
                    control={control}
                    errors={errors?.firstname}
                />

                <ReactHookInput<ContactForm, "companyName">
                    name="companyName"
                    placeholder="Firmenname (optional)"
                    rules={{ required: false }}
                    control={control}
                    ifNullish=""
                    asUndefined=""
                />

                <ReactHookInput<ContactForm, "email">
                    type="email"
                    name="email"
                    placeholder="E-Mail"
                    rules={{ required: true }}
                    control={control}
                />

                <ReactHookInput<ContactForm, "telephone">
                    type="telephone"
                    name="telephone"
                    placeholder="Telefonnummer"
                    rules={{ required: true }}
                    control={control}
                />

                <Headline style={{ marginTop: "10px" }} level={2}>
                    Adresse
                </Headline>

                <CityRow>
                    <ReactHookInput<ContactForm, "zipcode">
                        name="zipcode"
                        type="text"
                        placeholder="Suche Postleitzahl"
                        rules={{ required: true }}
                        control={control}
                    />

                    <ReactHookSelect<ContactForm, "city">
                        errors={errors.city}
                        name="city"
                        placeholder="Ort"
                        rules={{ required: true }}
                        control={control}
                        options={cities.map((city) => ({
                            value: city.ONAME,
                            text: city.ONAME,
                        }))}
                    />
                </CityRow>

                <StreetRow>
                    <ReactHookSelect<ContactForm, "street">
                        errors={errors.street}
                        name="street"
                        placeholder="Straße"
                        rules={{ required: true }}
                        control={control}
                        defaultValue={street}
                        options={streets.map((street) => ({
                            value: street,
                            text: street,
                        }))}
                    />

                    <ReactHookInput<ContactForm, "houseNumber">
                        name="houseNumber"
                        type="text"
                        placeholder="Hausnummer"
                        rules={{ required: true }}
                        control={control}
                    />
                </StreetRow>

                <ButtonWrapper>
                    <LinkComponent
                        button
                        color={"darkGrey"}
                        link={{
                            href: "/fluessiggas",
                            text: "Zurück",
                        }}
                    />
                    <Button color="red" type="submit">
                        Weiter
                    </Button>
                </ButtonWrapper>
            </form>
        </>
    );
};

export default LiquidGasContactForm;
