import React from "react";

import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import LiquidGasContactForm from "../../../components/pages/LiquidGas/components/Inquiry/LiquidGasContactForm";
import Container from "../../../components/shared/Container";
import NewHeadline from "../../../components/shared/NewHeadline";
import Page from "../../../components/shared/Page";

const LiquidGasInquiryPage = () => {
    const theme = useTheme();
    const additionalStyles = css`
        font-family: var(--font-family-vito);
        color: ${theme.colors.blue.toString()};
    `;
    const FormContainer = styled.div`
        padding-top: 20px;
        margin-top: 20px;
    `;
    return (
        <Page>
            <Container slim css={additionalStyles}>
                <NewHeadline is={"1"} lookLike={1} underline>
                    Anfrage Flüssiggas-Tank
                </NewHeadline>
                <FormContainer>
                    <LiquidGasContactForm />
                </FormContainer>
            </Container>
        </Page>
    );
};

export default LiquidGasInquiryPage;
